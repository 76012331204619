import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class CommercialAutoInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Commercial Auto Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Commercial vehicles can be one of the biggest exposures your Baton Rouge company can face as part of it’s daily operations. Contact us to get the right commercial auto insurance policy for your Louisiana based business." }]}   
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Fleet of commercial vehicles"/>
        <Callout
          title="Commercial Auto Insurance"
          content="Many companies in many different industries need commercial vehicles to serve their customers. However, commercial vehicles can be one of the biggest exposures your Baton Rouge based company can face as part of its daily operations. That’s why it is important to get the right commercial auto insurance policy to make sure you are not exposed to unnecessary risk."
        />
        <div className="site-body page-content">
          <h2>What Commercial Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Bodily Injury.</strong> Covers you for bodily injury or death from an accident for which an employee is at fault and often covers associated legal defense expenses.</li>
          <li><strong>Property Damage.</strong> Protects you if a company vehicle accidentally damages another person or business’s property.</li>
          <li><strong>Medical Payments.</strong> Whether you are at fault or not, this usually pays medical expenses of the driver and passengers in the vehicle that are in an accident.</li>
          <li><strong>Uninsured Motorists.</strong> This pays for injuries and sometimes certain property damage when involved in an accident with a driver who is uninsured. This will also usually cover hit-and-run accidents.</li>
          <li><strong>Comprehensive.</strong> This covers vehicle damage resulting from flood, fire, vandalism, theft, and other covered perils.</li>
          <li><strong>Collision.</strong> Pays for the damage to a vehicle when it is hit or hit another object.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="Personal auto insurance does not protect you if you are self-employed and are using your personal vehicle for business purposes. It only covers driving for leisure, commuting and other personal use."
            source="Insurance Information Institute (I.I.I.)"
          />
          <h2>What Commercial Auto Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Personal Vehicles.</strong> When an employee’s car is damaged from using their personal car for business use.</li>
          <li><strong>Non-employee Damage.</strong> When a company vehicle is damaged by a friend or relative of an employee.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Commercial Auto Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default CommercialAutoInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "commercial-auto-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-13.jpg" }) {
      ...mainBg
    }
  }
`;
